@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overflow-x: hidden;
  scroll-behavior: smooth;
  margin: 0;
    padding: 0;
}
